var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "data" },
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            staticStyle: { width: "100%" },
            attrs: { data: _vm.tableData },
          },
          [
            _c("el-table-column", {
              attrs: {
                type: "index",
                label: "序号",
                align: "center",
                "min-width": "100",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "cardNo",
                label: "卡号",
                align: "center",
                "min-width": "100",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "orderNumber",
                label: "订单号",
                align: "center",
                "min-width": "80",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "startTime",
                label: "创建时间",
                align: "center",
                "min-width": "120",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "orderGold",
                label: "订单金额",
                align: "center",
                "min-width": "120",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "orderType",
                label: "订单类型",
                align: "center",
                "min-width": "120",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "orderState",
                label: "状态",
                align: "center",
                "min-width": "120",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "endTime",
                label: "预计结束时间",
                align: "center",
                "min-width": "120",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("el-pagination", {
          attrs: {
            "current-page": _vm.page,
            "page-size": _vm.pageSize,
            total: _vm.total,
          },
          on: { "current-change": _vm.handleCurrentChange },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }