<template>
  <div>
    <div class="data">
      <el-table v-loading="loading" :data="tableData" style="width: 100%">
        <el-table-column type="index" label="序号" align="center" min-width="100"></el-table-column>
        <el-table-column prop="cardNo" label="卡号" align="center" min-width="100"></el-table-column>
        <el-table-column prop="orderNumber" label="订单号" align="center" min-width="80"></el-table-column>
        <el-table-column prop="startTime" label="创建时间" align="center" min-width="120"></el-table-column>
        <el-table-column prop="orderGold" label="订单金额" align="center" min-width="120"></el-table-column>
        <el-table-column prop="orderType" label="订单类型" align="center" min-width="120"></el-table-column>
        <el-table-column prop="orderState" label="状态" align="center" min-width="120"></el-table-column>
        <el-table-column prop="endTime" label="预计结束时间" align="center" min-width="120"></el-table-column>
        <!--<el-table-column label="操作" align="center" min-width="80">
          <template slot-scope="scope">
            <el-link @click="detailHandler(scope.row)">详情</el-link>
          </template>
</el-table-column>-->
      </el-table>
      <el-pagination @current-change="handleCurrentChange" :current-page="page" :page-size="pageSize"
        :total="total"></el-pagination>
    </div>
  </div>
</template>
<script>
import { getUseRecods } from '@/api/card'
import { getOrderData } from '@/api/order'
import { getToken } from '@/utils/auth'
export default {
  data() {
    return {
      tableData: [],
      cardNo: this.$route.query.cardNo,
      page: 1,
      pageSize: 10,
      loading: true,
      total: 0
    }
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.loading = true
      let param = {
        cardNo: this.cardNo,
        pageNo: this.page,
        pageSize: this.pageSize
      }
      getUseRecods(param).then(res => {
        this.tableData = [...res.data.records]
        this.total = res.data.total
        this.loading = false
      })
    },
    detailHandler(row) {
      let param = {
        orderNumber: row.orderNumber
      }
      getOrderData(param).then(res => {
        this.dialogVisible = true
        this.data = Object.assign({}, res.data)
      })
    },
    handleCurrentChange(val) {
      this.page = val
      this.getDataList()
    }
  }
}
</script>
<style scoped>
.data {
  margin-left: 20px;
  margin-top: 20px;
  margin-right: 10px;
}
</style>
