<template>
  <div class="main">
    <div class="content-box">
      <div class="title">
        <el-select clearable v-model="operatorId" placeholder="请选择代理商">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.operatorNm"
            :value="item.operatorId"
          ></el-option>
        </el-select>
        <el-input
          maxlength="15"
          style="width:300px;margin-left:20px;"
          placeholder="搜索卡号/用户手机号"
          v-model="search"
        >
          <el-button
            slot="suffix"
            @click="searchData"
            type="primary"
            icon="el-icon-search"
            style="border-radius:0 4px 4px 0;margin-right:-5px"
            >搜索</el-button
          >
        </el-input>

        <el-button
          @click="addHandler"
          type="primary"
          style="margin-left:20px;"
          icon="el-icon-plus"
          >新增</el-button
        >
        <el-button
          @click="importCard"
          type="primary"
          style="margin-left:20px;"
          icon="el-icon-upload2"
          >批量导入</el-button
        >
        <el-button
          @click="downloadTemplate"
          type="primary"
          style="margin-left:20px;"
          icon="el-icon-download"
          >下载导入模版</el-button
        >
      </div>
    </div>
    <div class="content-box">
      <el-table
        v-loading="loading"
        border
        :data="tableData"
        style="width: 100%"
      >
        <el-table-column
          type="index"
          label="序号"
          align="center"
          width="80"
        ></el-table-column>
        <el-table-column
          prop="cardNo"
          label="卡号"
          align="center"
          min-width="120"
        ></el-table-column>
        <el-table-column
          prop="deviceType"
          align="center"
          label="卡类型 "
          :formatter="cpDeviceType"
          min-width="80"
        ></el-table-column>
        <!--                <el-table-column prop="realName" label="代理商" align="center" min-width="80" :show-overflow-tooltip="true"></el-table-column>-->
        <el-table-column
          prop="userName"
          label="用户"
          align="center"
          min-width="80"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="sum"
          label="余额"
          align="right"
          min-width="60"
          :formatter="priceFormat"
        ></el-table-column>
        <el-table-column
          prop="state"
          align="center"
          label="状态"
          min-width="60"
        ></el-table-column>
        <el-table-column
          prop="mobile"
          label="用户手机号"
          align="center"
          min-width="120"
        ></el-table-column>
        <!--                <el-table-column prop="createTime" label="创建时间" align="center" min-width="130" :show-overflow-tooltip="true"></el-table-column>-->
        <el-table-column
          prop="bindTime"
          label="绑定时间"
          align="center"
          min-width="130"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column label="操作" align="center" width="280">
          <template slot-scope="scope">
            <div class="editCell">
              <el-button
                type="primary"
                @click="editCardHandler(scope.row)"
                v-if="scope.row.state !== '挂失'"
              >
                绑定用户
              </el-button>
              <el-button type="success" @click="getUseRecodsHandler(scope.row)"
                >使用记录</el-button
              >
              <!-- <el-button type="primary" @click="chongzhiCardHandler(scope.row)"
                >充值</el-button
              > -->
              <el-button
                type="danger"
                v-if="scope.row.state === '正常'"
                @click="lossCardHandler(scope.row)"
                >挂失</el-button
              >
              <el-button @click="viewCardHandler(scope.row)">查看</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-size="pageSize"
        :total="total"
      ></el-pagination>
    </div>
    <el-dialog
      style="padding: 100px;"
      title="批量导入"
      :visible.sync="importDialogVisible"
      width="40%"
    >
      <div style="display: flex">
        <el-upload
          class="upload-demo"
          ref="upload_card"
          :action="uploadUrl"
          :headers="headers"
          :data="uploadData"
          :file-list="fileList"
          :on-success="handleUploadSuccess"
        >
          <el-button type="primary">导入</el-button>
        </el-upload>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="errDialogVisible" width="40%">
      <el-table :data="uploadErrData" style="width: 100%" height="250">
        <el-table-column fixed prop="cardNo" label="卡号" width="150">
        </el-table-column>
        <el-table-column fixed prop="message" label="错误信息" width="150">
        </el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog title="充值" :visible.sync="dialogVisibleChongzhi" width="400px">
      <el-form
        ref="formChongzhi"
        :model="formDataChongzhi"
        label-width="100px"
        :rules="formRule"
      >
        <el-form-item class="nav-form-item" label="卡号" prop="amount">
          <span>{{ formDataChongzhi.cardNo }}</span>
        </el-form-item>
        <el-form-item class="nav-form-item" label="余额" prop="amount">
          <span>{{ formDataChongzhi.sum }}</span>
        </el-form-item>
        <el-form-item class="nav-form-item" label="充值金额" prop="amount">
          <el-input
            maxlength="6"
            type="text"
            v-model="formDataChongzhi.amount"
            style="width:200px;"
            placeholder="请输入充值金额"
            clearable
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisibleChongzhi = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="confimChongzhiHandler"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      :title="popupModalTitle"
      :visible.sync="dialogVisible"
      :width="isAdd ? '700px' : '400px'"
    >
      <el-form
        ref="form"
        :model="formData"
        label-width="100px"
        :rules="formRule"
      >
        <div style="display: flex;">
          <el-form-item class="nav-form-item" label="卡号" prop="cardNo">
            <el-input
              :disabled="addDisable"
              maxlength="16"
              type="text"
              v-model="formData.cardNo"
              style="width:200px;"
              placeholder="请输入卡号"
              clearable
            />
          </el-form-item>
          <el-form-item
            class="nav-form-item"
            label="物理卡号"
            prop="physicalCardNo"
            v-if="isAdd"
          >
            <el-input
              :disabled="addDisable"
              maxlength="16"
              type="text"
              v-model="formData.physicalCardNo"
              style="width:200px;"
              placeholder="请输入物理卡号"
              clearable
            />
          </el-form-item>
          <!--                <el-form-item class="nav-form-item" label="卡类型" prop="deviceType">-->
          <!--                  <el-select style="width:200px" v-model="formData.deviceType" placeholder="请选择">-->
          <!--                    <el-option label="二轮车" value="2"></el-option>-->
          <!--                    <el-option label="四轮车" value="4"></el-option>-->
          <!--                  </el-select>-->
          <!--                </el-form-item>-->
        </div>
        <div style="display: flex;">
          <el-form-item
            :style="style"
            class="nav-form-item"
            label="余额"
            prop="sum"
          >
            <el-input
              maxlength="6"
              type="text"
              v-model="formData.sum"
              style="width:200px;"
              placeholder="请输入余额"
              clearable
            />
          </el-form-item>
          <el-form-item class="nav-form-item" label="电话" prop="mobile">
            <el-input
              maxlength="11"
              v-model="formData.mobile"
              style="width:200px;"
              placeholder="请输入电话"
              clearable
            />
          </el-form-item>
        </div>

        <el-form-item class="nav-form-item" label="运营商" prop="userID">
          <el-select v-model="formData.operator" placeholder="运营商">
            <el-option
              v-for="(item, index) in agentData"
              :label="item.operatorNm"
              :value="item.operatorId"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="confimHandler">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="查看IC卡信息" :visible.sync="viewDisable" width="700px">
      <el-form
        ref="form"
        :model="formData"
        label-width="100px"
        :rules="formRule"
      >
        <div style="display: flex;">
          <el-form-item class="nav-form-item" label="卡号" prop="cardNo">
            <el-input
              :disabled="true"
              maxlength="16"
              type="text"
              v-model="formData.cardNo"
              style="width:200px;"
              placeholder="请输入卡号"
              clearable
            />
          </el-form-item>
          <el-form-item
            class="nav-form-item"
            label="物理卡号"
            prop="physicalCardNo"
          >
            <el-input
              :disabled="true"
              maxlength="16"
              type="text"
              v-model="formData.physicalCardNo"
              style="width:200px;"
              placeholder="请输入物理卡号"
              clearable
            />
          </el-form-item>
          <!--                <el-form-item class="nav-form-item" label="卡类型" prop="deviceType">-->
          <!--                  <el-select style="width:200px" v-model="formData.deviceType" placeholder="请选择">-->
          <!--                    <el-option label="二轮车" value="2"></el-option>-->
          <!--                    <el-option label="四轮车" value="4"></el-option>-->
          <!--                  </el-select>-->
          <!--                </el-form-item>-->
        </div>
        <div style="display: flex;">
          <el-form-item
            :style="style"
            class="nav-form-item"
            label="余额"
            prop="sum"
          >
            <el-input
              :disabled="true"
              maxlength="6"
              type="text"
              v-model="formData.sum"
              style="width:200px;"
              placeholder="请输入余额"
              clearable
            />
          </el-form-item>
          <el-form-item class="nav-form-item" label="电话" prop="mobile">
            <el-input
              :disabled="true"
              maxlength="11"
              v-model="formData.mobile"
              style="width:200px;"
              placeholder="请输入电话"
              clearable
            />
          </el-form-item>
        </div>
        <div style="display: flex;">
          <el-form-item class="nav-form-item" label="运营商" prop="userID">
            <el-select
              v-model="formData.operator"
              placeholder="运营商"
              :disabled="true"
            >
              <el-option
                v-for="(item, index) in agentData"
                :label="item.operatorNm"
                :value="item.operatorId"
                :key="index"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            class="nav-form-item"
            label="创建时间"
            prop="createTime"
          >
            <el-input
              :disabled="true"
              maxlength="11"
              v-model="formData.createTime"
              style="width:200px;"
              placeholder="请输入创建时间"
              clearable
            />
          </el-form-item>
        </div>
        <div style="display: flex;">
          <el-form-item class="nav-form-item" label="绑定时间" prop="bindTime">
            <el-input
              :disabled="true"
              maxlength="11"
              v-model="formData.bindTime"
              style="width:200px;"
              placeholder="请输入绑定时间"
              clearable
            />
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="viewDisable = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  getCardList,
  addCard,
  lossCard,
  editCard,
  chongzhiCard,
} from "@/api/card";
import { getDataScopeList } from "@/api/user";
import { getOperatorSelect } from "@/api/operator";
import { getToken } from "@/utils/auth";

export default {
  data() {
    var validateCardNo = (rule, value, callback) => {
      var format = /^[a-fA-F0-9]{1,16}$/;
      if (value === "") {
        callback(new Error("请输入卡号(ABCDEF)+数字(0-9)"));
      } else if (!format.test(value)) {
        callback(new Error("请输入卡号(ABCDEF)+数字(0-9)"));
      } else {
        if (value.length !== 16) {
          callback(new Error("请输入16位数卡号"));
        } else {
          callback();
        }
      }
    };
    var validateMobile = (rule, value, callback) => {
      if (this.isAdd && !value) {
        callback();
        return;
      }
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else if (isNaN(value)) {
        callback(new Error("手机号必须为数字!"));
      } else if (value !== null && value.length !== 11) {
        callback(new Error("手机号必须为11位数字!"));
      } else {
        callback();
      }
    };
    return {
      uploadUrl: process.env.VUE_APP_BASE_API + "/sysCard/uploadCards",
      headers: {
        token: getToken(),
      },
      uploadData: {
        business: "advert",
        deviceType: 4,
      },
      addDisable: true,
      viewDisable: false,
      dialogVisible: false,
      errDialogVisible: false,
      importDialogVisible: false,
      dialogVisibleChongzhi: false,
      operatorId: "",
      agentData: [],
      uploadErrData: [],
      fileList: [],
      date: "",
      isAdd: true,
      search: "",
      tableData: [],
      page: 1,
      pageSize: 10,
      loading: true,
      formData: {
        cardNo: "",
        physicalCardNo: "",
        deviceType: "4",
        mobile: "",
        sum: 0,
        userName: "",
        operator: "",
      },
      formDataChongzhi: {
        amount: "",
        cardNo: "",
        sum: "",
      },
      total: 0,

      options: [],
      formRule: {
        cardNo: [
          {
            validator: validateCardNo,
            // message: "请输入卡号(ABCDEF)+数字(0-9)",
            trigger: "blur",
          },
        ],
        physicalCardNo: [
          {
            validator: validateCardNo,
            message: "请输入卡号(ABCDEF)+数字(0-9)",
            trigger: "blur",
          },
        ],
        mobile: [
          { validator: validateMobile, message: "请输入电话", trigger: "blur" },
        ],
        deviceType: [
          { required: true, message: "请选择卡类型", trigger: "blur" },
        ],
        // sum: [{required: true, message: '请输入余额', trigger: 'blur' }],
      },
      popupModalTitle: "新增四轮车卡",
      style: "",
    };
  },
  created() {
    this.getCardList();
    this.getDataScopeList();
    this.getAgentList();
  },
  methods: {
    cpDeviceType(row) {
      if (row.deviceType == 2) {
        return "二轮车";
      } else if (row.deviceType == 4) {
        return "四轮车";
      }
    },
    priceFormat(row) {
      var x = row.sum;
      var f = parseFloat(x);
      if (isNaN(f)) {
        return false;
      }
      var f = Math.round(x * 100) / 100;
      var s = f.toString();
      var rs = s.indexOf(".");
      if (rs < 0) {
        rs = s.length;
        s += ".";
      }
      while (s.length <= rs + 2) {
        s += "0";
      }
      return s;
    },

    downloadTemplate() {
      window.open(
        "http://e.nxptdn.com/user/file/template/import4card-template.xlsx"
      );
    },
    handleUploadSuccess(res, file) {
      this.$refs.upload_card.clearFiles();
      console.log(res);
      if (res.message.code == -1) {
        this.uploadErrData = res.data;
        this.errDialogVisible = true;
      } else {
        this.$message({
          message: "操作成功",
          type: "success",
        });
        this.getCardList();
      }
      this.fileList = [];
      this.importDialogVisible = false;
    },
    getAgentList() {
      getOperatorSelect().then((res) => {
        this.agentData = [...res.data];
      });
    },
    getDataScopeList() {
      getDataScopeList().then((res) => {
        console.log(res);
        if (res.data && res.data.length > 0) {
          this.options = [...res.data];
        }
      });
    },
    searchData() {
      this.page = 1;
      this.getCardList();
    },
    getCardList() {
      this.loading = true;
      let param = {
        userId: this.operatorId,
        deviceType: 4,
        keyWord: this.search,
        pageNo: this.page,
        pageSize: this.pageSize,
      };
      getCardList(param).then((res) => {
        if (res.message.status == 200) {
          this.tableData = [...res.data.records];
          this.total = res.data.total;
        }

        this.loading = false;
      });
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getCardList();
    },
    viewCardHandler(row) {
      this.formData.cardNo = row.cardNo;
      this.formData.physicalCardNo = row.physicalCardNo;
      this.formData.sum = row.sum;
      this.formData.mobile = row.mobile;
      this.formData.userName = row.userName;
      this.formData.bindTime = row.bindTime;
      this.formData.createTime = row.createTime;
      this.formData.operator = parseInt(row.operator);
      this.viewDisable = true;
    },
    addHandler() {
      this.addDisable = false;
      this.isAdd = true;
      this.dialogVisible = true;
      this.formData.cardNo = "";
      this.formData.physicalCardNo = "";
      this.formData.mobile = "";
      this.formData.sum = "";
      (this.popupModalTitle = "新增四轮车卡"), (this.style = "");
    },
    importCard() {
      this.importDialogVisible = true;
    },
    confimHandler() {
      this.$refs.form.validate((valid) => {
        console.log(valid);
        if (valid) {
          if (this.isAdd) {
            this.addCard();
          } else {
            this.editCard();
          }
        }
      });
    },
    confimChongzhiHandler() {
      this.$refs.formChongzhi.validate((valid) => {
        if (valid) {
          console.log(this.formDataChongzhi);
          chongzhiCard(this.formDataChongzhi).then((res) => {
            console.log(123);
            console.log(res);
            if (res.message.code == -1) {
              this.$message.error(res.message.message);
            } else {
              this.$message({
                message: "操作成功",
                type: "success",
              });
              this.dialogVisibleChongzhi = false;
              this.getCardList();
            }
          });
        }
      });
    },
    lossCardHandler(row) {
      this.$confirm("确定挂失所选项吗?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let param = {
          cardNo: row.cardNo,
        };
        lossCard(param).then((res) => {
          if (res.message.code == 0) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
            this.getCardList();
          } else {
            this.$message.error(res.data);
          }
        });
      });
    },
    editCardHandler(row) {
      this.isAdd = false;
      this.addDisable = true;
      this.formData.cardNo = row.cardNo;
      this.formData.physicalCardNo = row.physicalCardNo;
      this.formData.sum = row.sum;
      this.formData.mobile = row.mobile;
      this.formData.userName = row.userName;
      this.formData.operator = parseInt(row.operator);
      this.popupModalTitle = "绑定用户";
      this.style = "display:none";
      this.dialogVisible = true;
    },
    chongzhiCardHandler(row) {
      this.formDataChongzhi.cardNo = row.cardNo;
      this.formDataChongzhi.amount = "";
      this.formDataChongzhi.sum = row.sum;
      this.dialogVisibleChongzhi = true;
    },
    editCard() {
      editCard(this.formData).then((res) => {
        if (res.message.code == -1) {
          this.$message.error(res.message.message);
        } else {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.dialogVisible = false;
          this.getCardList();
        }
      });
    },
    addCard() {
      addCard(this.formData).then((res) => {
        if (res.message.code == -1) {
          this.$message.error(res.message.message);
        } else {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.dialogVisible = false;
          this.getCardList();
        }
      });
    },
    getUseRecodsHandler(row) {
      this.$router.push({
        path: "card/useRecods",
        query: {
          cardNo: row.cardNo,
        },
      });
    },
  },
};
</script>
<style scoped lang="scss">
.editCell {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
</style>
